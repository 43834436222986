import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getToken} from '../../store/tokens/tokens.actions';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const DataTableWB = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
          <TableCell>Номенклатура</TableCell>
            <TableCell>Наименование склада</TableCell>
            <TableCell>Количество</TableCell>
            <TableCell>На пути к клиенту</TableCell>
            <TableCell>На пути от клиента</TableCell>
            <TableCell>Общее количество</TableCell>
            <TableCell>Категория</TableCell>
            <TableCell>Тема</TableCell>
            <TableCell>Бренд</TableCell>
            <TableCell>Технический размер</TableCell>
            <TableCell>Цена</TableCell>
            <TableCell>Артикул поставщика</TableCell>
            <TableCell>Штрихкод</TableCell>
            <TableCell>Скидка</TableCell>
            <TableCell>В наличии</TableCell>
            <TableCell>В реализации</TableCell>
            <TableCell>Код СЦ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
                <TableCell>{item.nmId}</TableCell>
              <TableCell>{item.warehouseName}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{item.inWayToClient}</TableCell>
              <TableCell>{item.inWayFromClient}</TableCell>
              <TableCell>{item.quantityFull}</TableCell>
              <TableCell>{item.category}</TableCell>
              <TableCell>{item.subject}</TableCell>
              <TableCell>{item.brand}</TableCell>
              <TableCell>{item.techSize}</TableCell>
              <TableCell>{item.Price}</TableCell>
              <TableCell>{item.supplierArticle}</TableCell>
              <TableCell>{item.barcode}</TableCell>
              <TableCell>{item.Discount}</TableCell>
              <TableCell>{item.isSupply.toString()}</TableCell>
              <TableCell>{item.isRealization.toString()}</TableCell>
              <TableCell>{item.SCCode}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DataTableOzon = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Артикул</TableCell>
            <TableCell>FBS (Сейчас на складе)</TableCell>
            <TableCell>FBS (Зарезервировано)</TableCell>
            <TableCell>FBO (Сейчас на складе)</TableCell>
            <TableCell>FBO (Зарезервировано)</TableCell>
            <TableCell>Crossborder (Сейчас на складе)</TableCell>
            <TableCell>Crossborder (Зарезервировано)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.offer_id}</TableCell>
              {item.stocks.map((stock, i) => (
                <React.Fragment key={i}>
                  <TableCell>{stock.present}</TableCell>
                  <TableCell>{stock.reserved}</TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function renderDataTable(mname) {

  switch (mname) {
    case "Wildberries":
      return DataTableWB;
    case "Ozon":
      return DataTableOzon;
  }
}



export default function Token({ authenticated }) {
    const { mid, tid } = useParams();
    console.log(mid, tid)
    const dispatch = useDispatch();
    const { loading, success, token } = useSelector((state) => state.token)
    useEffect(() => {
        dispatch(getToken({mid, tid}))
    }, [dispatch, mid, tid])
    const DataTableComponent = renderDataTable(token?.marketplace?.name);
    return (
        <>
            {loading ? (
                <Box sx={{ display: 'block', alignItems: 'center', textAlign: 'center', marginTop: 15 }}>
                    <CircularProgress color="secondary" />
                </Box>
            ) : (
                <>
                    {success ? (
                        <DataTableComponent data={token.stocks_data}/>
                    ) : (
                        <Box sx={{ display: 'block', alignItems: 'center', textAlign: 'center', marginTop: 15 }}>
                            <Typography variant="body1">Нет связи с сервером...</Typography>
                        </Box>
                    )}
                </>
            )}
        </>
    )
}