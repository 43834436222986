import { createSlice } from '@reduxjs/toolkit';

import { getToken } from './tokens.actions';

const initialState = {
    loading: false,
    error: null,
    token: null,
    success: false,
}

const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {},
    extraReducers: {
        [getToken.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getToken.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true
            state.token = payload
        },
        [getToken.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        }
    },
})

const { reducer } = tokenSlice;

export default reducer;