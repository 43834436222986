import http from '../../http';
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getToken = createAsyncThunk(
  'token/getToken',
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await http.get(`/marketplace/${token.mid}/token/${token.tid}`);
      localStorage.setItem("token", JSON.stringify(data));
      return data
    } catch (error) {
      return rejectWithValue(error.response.statusText)
    }
  }
)
